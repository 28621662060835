<template>
  <Modal @onClose="disableTag">
    <div class="cookie-banner">
      <div class="cookie-banner-container">
        <div class="cookie-banner-heading">
          <Icon name="cookie" :size="50" />
        </div>
        <div class="cookie-banner-body">
          <p class="text -body">{{ t("cookies.text") }}</p>
          <p @click="toggleReadMore" class="read-more text -body">
            {{ t("cookies.readMore") }}
          </p>
          <div class="cookie-banner-details" v-if="showCookieDetails">
            <h2>Cookies Policy</h2>
            <hr />
            <p>
              Our Cookies Policy was last updated on 21 Jan 2023.<br />
              This Cookies Policy explains what Cookies are and how We use them.
              You should read this policy so You can understand what type of
              cookies We use, or the information We collect using Cookies and
              how that information is used. Cookies do not typically contain any
              information that personally identifies a user, but personal
              information that we store about You may be linked to the
              information stored in and obtained from Cookies. For further
              information on how We use, store and keep your personal data
              secure, see our Privacy Policy. This Disclaimer was generated by
              TermsFeed Cookies Policy Generator. We do not store sensitive
              personal information, such as mailing addresses, account
              passwords, etc. in the Cookies We use.
            </p>
            <h3>Interpretation and Definitions</h3>
            <hr />
            <h4>Interpretation</h4>

            <p>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions.
            </p>
            <p>
              The following definitions shall have the same meaning regardless
              of whether they appear in singular or in plural.
            </p>
            <h4>Definitions</h4>
            <p>For the purposes of this Cookies Policy:</p>
            <ul>
              <li>
                "Cookies" means small files that are placed on Your computer,
                mobile device or any other device by a website, containing
                details of your browsing history on that website among its many
                uses.
              </li>
              <li>"Website" refers to FoCaMu, accessible from focamu.com</li>
              <li>
                "You" means the individual accessing or using the Website, or a
                company, or any legal entity on behalf of which such individual
                is accessing or using the Website, as applicable.
              </li>
            </ul>
            <h3>The use of the Cookies</h3>
            <hr />
            <h4>Type of Cookies We Use</h4>
            <p>
              Cookies can be "Persistent" or "Session" Cookies. Persistent
              Cookies remain on your personal computer or mobile device when You
              go offline, while Session Cookies are deleted as soon as You close
              your web browser.
            </p>
            <p>
              We use both session and persistent Cookies for the purposes set
              out below:
            </p>
            <ul>
              <li>
                <p class="text -bold">Necessary / Essential Cookies:</p>
                <p>Type: Session Cookies</p>
                <p>Administered by: Us</p>
                <p>
                  Purpose: These Cookies are essential to provide You with
                  services available through the Website and to enable You to
                  use some of its features. They help to authenticate users and
                  prevent fraudulent use of user accounts. Without these
                  Cookies, the services that You have asked for cannot be
                  provided, and We only use these Cookies to provide You with
                  those services.
                </p>
              </li>
              <li>
                <p class="text -bold">
                  Cookies Policy/Notice Acceptance Cookies
                </p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>
                  Purpose: These Cookies identify if users have accepted the use
                  of cookies on the Website.
                </p>
              </li>
              <li>
                <p class="text -bold">Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>
                  Purpose: These Cookies allow us to remember choices You make
                  when You use the Website, such as remembering your login
                  details or language preference. The purpose of these Cookies
                  is to provide You with a more personal experience and to avoid
                  You having to re-enter your preferences every time You use the
                  Website.
                </p>
              </li>
              <li>
                <p class="text -bold">Tracking and Performance Cookies</p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Third-Parties</p>
                <p>
                  Purpose: These Cookies are used to track information about
                  traffic to the Website and how users use the Website. The
                  information gathered via these Cookies may directly or
                  indirectly identify you as an individual visitor. This is
                  because the information collected is typically linked to a
                  pseudonymous identifier associated with the device you use to
                  access the Website. We may also use these Cookies to test new
                  pages, features or new functionality of the Website to see how
                  our users react to them.
                </p>
              </li>
            </ul>
            <h3>Your Choices Regarding Cookies</h3>
            <hr />
            <p>
              If You prefer to avoid the use of Cookies on the Website, first
              You must disable the use of Cookies in your browser and then
              delete the Cookies saved in your browser associated with this
              website. You may use this option for preventing the use of Cookies
              at any time. If You do not accept Our Cookies, You may experience
              some inconvenience in your use of the Website and some features
              may not function properly. If You'd like to delete Cookies or
              instruct your web browser to delete or refuse Cookies, please
              visit the help pages of your web browser.
            </p>
            <p>
              For the Chrome web browser, please visit this page from Google:
            </p>
            <p>https://support.google.com/accounts/answer/32050</p>
            <p>
              For the Internet Explorer web browser, please visit this page from
              Microsoft: http://support.microsoft.com/kb/278835
            </p>
            <p>
              For the Firefox web browser, please visit this page from Mozilla:
              https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
            </p>
            <p>
              For the Safari web browser, please visit this page from Apple:
              https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
            </p>
            <p>
              For any other web browser, please visit your web browser's
              official web pages.
            </p>
            <p>
              Contact Us If you have any questions about this Cookies Policy,
              You can contact us: By visiting this page on our website:
              focamu.com By sending us an email: focamu@gmail.com
            </p>
          </div>
        </div>
        <div class="cookie-banner-footer">
          <button class="cookie-button-cancel" @click="disableTag">
            {{ t("cookies.dismiss") }}
          </button>
          <button class="cookie-button-submit" @click="enableGtag">
            {{ t("cookies.confirm") }}
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { useAppStore } from "@/stores/app.store";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import Modal from "@/components/common/Modal.vue";
import { bootstrap } from "vue-gtag";
import Icon from "@/components/component-library/Icon.vue";

const { closeCookies } = useAppStore();
const { t } = useI18n();
const showCookieDetails = ref(false);
const emits = defineEmits(["close"]);

const enableGtag = async () => {
  await bootstrap();
  localStorage.setItem("cookies-enabled", "true");
  emits("close");
  closeCookies();
};
const disableTag = () => {
  localStorage.setItem("cookies-enabled", "false");
  emits("close");
  closeCookies();
};
const toggleReadMore = async () => {
  showCookieDetails.value = !showCookieDetails.value;
};
</script>

<style lang="scss">
.cookie-banner {
  max-width: 30rem;
  @media only screen and (min-device-width: 1024px) {
    max-width: 60rem;
  }
  width: 100%;
  &-body {
    margin: 2rem 0 3rem;
  }
  &-details {
    max-height: 30rem;
    overflow-y: scroll;
    border-radius: 1rem;
    background: var(--color-black-900);
    color: var(--color-black-50);
    text-align: left;
    padding: 2rem;
    hr {
      margin: 1.5rem 0;
      height: 0.2rem;
      background: rgba(var(--color-black-50-rgb), 0.1);
      border-radius: 2rem;
      border: none;
    }
    p {
      margin: 1rem 0;
    }
    ul {
      padding: 0 1.5rem;
    }
  }
  &-footer {
    display: flex;
    gap: 2rem;
  }
  .read-more {
    text-decoration: underline;
    color: var(--color-primary-600);
    font-weight: bold;
  }
}

.cookie-button-submit {
  background-color: var(--color-black-900);
  color: var(--color-black-50);
  border: none;
  display: block;
  font-weight: bold;
  border-radius: 1rem;
  padding: 1.5rem;
  font-size: 1.6rem;
  flex: 1;
}

.cookie-button-cancel {
  background-color: var(--color-background);
  box-shadow: inset 0 0 0 0.2rem var(--color-black-900);
  color: var(--color-black-950);
  border: none;
  font-weight: bold;
  border-radius: 1rem;
  padding: 1.5rem;
  font-size: 1.6rem;
  flex: 1;
}
</style>
